<template>
  <fieldset class="space-y-5">
    <div class="relative flex">
      <div class="flex items-center base-input-checkbox relative">
        <input
          :id="name"
          :aria-describedby="`${name}-description`"
          :name="name"
          type="checkbox"
          class="absolute h-0 w-0 opacity-0 cursor-auto"
          v-model="model"
        />

        <div
          class="absolute bg-white left-0 checkmark h-4 w-4 text-accent-color rounded-[3px]"
          :class="{
            border: !invalid,
            'border border-error': invalid,
          }"
        ></div>

        <label
          v-if="label"
          :for="name"
          class="cursor-pointer font-medium text-sm leading-base flex items-center"
          :class="{
            'text-font-primary ': !invalid,
            'text-error': invalid,
          }"
        >
          <img
            v-if="image"
            :src="image"
            alt=""
            class="h-x6 w-x6 min-w-x6 rounded-half mr-2.5 object-cover"
          />
          <p class="pl-[26px]">{{ label }}</p>
        </label>

        <label
          v-else
          :for="name"
          class="cursor-pointer font-medium text-font-primary text-sm leading-base flex items-center mr-[26px]"
        >
          <div
            class="absolute z-0 left-0 checkmark h-4 w-4 text-accent-color rounded-[3px] mr-[26px]"
            :class="[
              modelValue ? 'border border-accent-color' : 'border  bg-white',
            ]"
          ></div>
        </label>
      </div>
    </div>
  </fieldset>
</template>

<script lang="ts" setup>
const props = defineProps({
  label: { type: String, default: false },
  name: { type: [String, Number], default: "1" },
  image: { type: String, default: "" },
  invalid: { type: Boolean, default: false },
  modelValue: {
    type: [Array, Boolean],
  },
  value: [Number, String],
});

const emit = defineEmits(["update:modelValue"]);

const model = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});
</script>

<style>
.base-input-checkbox input:checked ~ .checkmark {
  @apply bg-accent-color border-accent-color;
}

.base-input-checkbox input:checked ~ .checkmark::after {
  @apply block;
}

.base-input-checkbox input:checked ~ label {
  @apply text-accent-color;
}

.base-input-checkbox .checkmark::after {
  content: "";
  @apply absolute h-1.5 w-1.5 bg-white rounded-[1px] top-1/2 left-1/2 hidden transform -translate-x-1/2 -translate-y-1/2;
}
</style>
