<template>
  <div class="flex items-center min-w-x20">
    <img
      v-if="img?.length && !img.includes('assets')"
      :src="img"
      alt=""
      class="object-cover w-x8 h-x8 mr-[16px]"
      :class="[rounded || 'rounded-x1']"
    />
    <div
      v-else-if="
        customer &&
        useGetCustomerInitials(topText) &&
        !img?.length &&
        !img.includes('assets')
      "
      class="flex w-x8 h-x8 items-center justify-center bg-grey-500 text-xs mr-[16px]"
      :class="[rounded || 'rounded-x1']"
    >
      <p class="text-white">{{ useGetCustomerInitials(topText) }}</p>
    </div>

    <img
      v-else-if="customer && !img?.length && !useGetCustomerInitials(topText)"
      src="~/assets/img/default-user.png"
      alt=""
      class="object-contain w-x8 h-x8 mr-[16px]"
      :class="[rounded || 'rounded-x1']"
    />

    <img
      v-else-if="!customer"
      src="~/assets/img/default-img.svg"
      alt=""
      class="object-contain w-x8 h-x8 mr-[16px]"
      :class="[rounded || 'rounded-x1']"
    />

    <div>
      <p class="text-sm leading-base text-font-primary">
        {{ topText }}
        <span
          v-if="symbols?.length"
          v-html="symbols"
          class="inline-flex gap-x1"
        ></span>
      </p>
      <p class="text-xs leading-base-sm text-font-secondary">
        {{ bottomText }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useProductsStore } from "~/store/products";

const props = defineProps({
  img: {
    type: String,
    default: "",
  },
  topText: {
    type: String,
    default: "",
  },
  symbols: {
    type: String,
    default: "",
  },
  bottomText: {
    type: String,
    default: "",
  },
  rounded: String,
  customer: {
    type: Boolean,
    default: false,
  },
});

const productsStore = useProductsStore();
</script>
